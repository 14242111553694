@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Roboto.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Roboto.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/Roboto.woff') format('woff'), /* Pretty Modern Browsers */
       url('/assets/fonts/Roboto.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/Roboto.svg#svgFontName') format('svg'); /* Legacy iOS */
}

html, body {width:100%; min-height:100%; };
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', Tahoma, Arial;
}

* {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: 'Roboto', Tahoma, Arial;
  outline: none;
}

#root { background: rgba(0,0,0,.02); float: left; width: 100%; }
.ui.table {
  margin:0;
  width: calc(100% - 270px);
}
.ui.table:last-child { margin-bottom: 30px; }
.last-td {
  display: flex;
  justify-content: space-between;
}

.last-td > * { cursor: pointer }

.ui.form.block {width:100%}
.react-datepicker-popper { width: 285px; }
.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
}
.react-datepicker__input-container > input {padding-left: 35px!important;}

.ReactPasswordStrength { border:0!important; }
.ReactPasswordStrength > input { width: 100%; }
.ReactPasswordStrength-strength-desc { display: flex; justify-content: flex-end; width: 100%!important; }

/* login */
img[alt="logo"] {
  width: 300px;
  padding: 0 0 50px 0;
}

.ui.text.loader.table-loader {
  position: relative;
  top: 25%;
}

.popup { z-index: 9999!important; }
.rc-anchor-light { width: 99%!important; }
.till-font, .till-font > input {
  font-family: "'Roboto', Arial",sans-serif!important;
  font-size: 16px!important;
  font-weight: 300!important;
}                 